<template>
    <div class="px-10 py-10">
        <Loader v-if="isLoading"/>
        <div class="row mb-2">
            <div class="col-12 col-md-6">
                <h3 class="my-4">GU</h3>
                <div class="form-group">
                    <label>
                        {{ $t('METADATA_MAPPING.FORM.GU.APP_TYPE')}}
                    </label>
                    <st-autocomplete
                        v-model="model.applicationType"
                        :options="applicationsTypes"
                        name="applicationType"
                    >
                    </st-autocomplete>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <h3 class="my-4">Arhiva</h3>
                <div class="form-group">
                    <label>
                        {{ $t('METADATA_MAPPING.FORM.ARCHIVE.DOC_TYPE')}}
                    </label>
                    <st-autocomplete
                        v-model="model.archiveDocType"
                        :options="documentTypes"
                        name="archiveDocType"
                    >
                    </st-autocomplete>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>
                        {{ $t('METADATA_MAPPING.FORM.GU.METADATA')}}
                    </label>
                    <st-autocomplete
                        v-model="model.appDocMetadata"
                        :options="applicationsMetadata"
                        ref="appDocMetadata"
                        name="appDocMetadata"
                    >
                    </st-autocomplete>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>
                        {{ $t('METADATA_MAPPING.FORM.ARCHIVE.METADATA')}}
                    </label>
                    <st-autocomplete
                        v-model="model.archiveDocMetadata"
                        :options="documentMetadata"
                        ref="archiveDocMetadata"
                        name="archiveDocMetadata"
                    >
                    </st-autocomplete>
                    <small v-if="documentMetadata.length">*{{ $t('METADATA_MAPPING.MANDATORY_NOTE')}}</small>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end w-100">
            <st-button
                variant="primary"
                class="font-size-h6 px-5 py-3 my-3 mr-3"
                :callback="doSave"
            >
                <span>{{ $t('GENERAL.BUTTON.SAVE') }}</span>
            </st-button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'MappingsMatch',
    data() {
        return {
            model:{
                archiveDocType: '',
                archiveDocMetadata: '',
                applicationType: '',
                appDocMetadata: ''
            },
            options: []
        }
    },
    computed: {
        ...mapGetters({
            documentTypes: 'metadataMapping/list/documentTypes',
            documentMetadata: 'metadataMapping/list/documentMetadata',
            applicationsTypes: 'metadataMapping/list/applicationsTypes',
            applicationsMetadata: 'metadataMapping/list/applicationsMetadata',
            loading: 'shared/loading',
            rows: 'metadataMapping/list/allApplicationsMetadata',
        }),
        isLoading() {
            return this.loading['metadataMapping/getAllDocumentsTypes'] ||
                    this.loading['metadataMapping/getDocumentTypeById'] ||
                    this.loading['metadataMapping/getApplicationsTypes'] ||
                    this.loading['metadataMapping/getApplicationMetadataStatic'] ||
                    this.loading['metadataMapping/getApplicationMetadata'];
        }
    },
    watch: {
        'model.archiveDocType': {
            deep: true,
            immediate: true,
            handler(value) {
                if (value) {
                    this.model.archiveDocMetadata = '';
                    this.doGetDocumentTypeById(parseInt(value));
                }
            },
        },
        'model.applicationType': {
            deep: true,
            immediate: true,
            handler(value) {
                if (value) {
                    this.model.appDocMetadata = '';
                    const selectedAppType = this.applicationsTypes.find( app => app.value === value);
                    this.doGetApplicationMetadata(selectedAppType.value)
                    .then(()=> {
                        this.$emit('applicationMetadataSelected', selectedAppType);
                    })
                }
            },
        },
    },
    async created() {
        // TODO: remove testEnvVariable after  Environment Variables are set in Docker
        const testEnvVariable = process.env.VUE_APP_SUPER_USER;
        await this.doGetApplicationsTypes();
        await this.doGetApplicationMetadataStatic();
        await this.doGetAllDocumentsTypes();
    },
    methods: {
        ...mapActions({
            doGetAllDocumentsTypes: 'metadataMapping/list/getAllDocumentsTypes',
            doGetDocumentTypeById: 'metadataMapping/list/getDocumentTypeById',
            doGetApplicationsTypes: 'metadataMapping/list/getApplicationsTypes',
            doGetApplicationMetadata: 'metadataMapping/list/getApplicationMetadata',
            doGetApplicationMetadataStatic: 'metadataMapping/list/getApplicationMetadataStatic',
            doUpdateMapping: 'metadataMapping/list/updateMapping',
            doUpdateMappedMetadata: 'metadataMapping/list/updateMappedMetadata',
        }),
        doSave () {
            const selectedArchiveMetadata = this.documentMetadata.find( doc => doc.value === this.model.archiveDocMetadata);
            const selectedAppMetadata = this.applicationsMetadata.find( meta => meta.value === this.model.appDocMetadata);
            const selectedAppType = this.applicationsTypes.find( app => app.value === this.model.applicationType);
            const payload = {
                archive_metadata_name: selectedArchiveMetadata.docObj.name,
                archive_metadata_id: selectedArchiveMetadata.docObj.id,
                archive_metadata_label: selectedArchiveMetadata.docObj.label,
                metadata: selectedAppMetadata.text,
                application_type: this.model.applicationType,
            }

            const archivedMetadataMapped = this.rows.find((row) => row.archive_metadata_id === selectedArchiveMetadata.value);

            if (archivedMetadataMapped) {
                const text = this.$t('METADATA_MAPPING.CONFIRMATION_MESSAGE',
                            {
                                label: archivedMetadataMapped.archive_metadata_label,
                                metadata: archivedMetadataMapped.metadata,
                                text: selectedAppMetadata.text
                            });
                this.$alert({
                        type: 'warning',
                        isTextHtml: true,
                        text,
                        cancelButtonText: this.$t('GENERAL.CLOSE'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('GENERAL.YES'),
                        cancelButtonText: this.$t('GENERAL.NO'),
                        hasConfirmation: true,
                        confirmCallback: () => {
                            this.doUpdateMappedMetadata({
                                id: archivedMetadataMapped.id,
                                payload
                            })
                            this.doGetApplicationMetadata(selectedAppType.value);
                            this.$emit('applicationMetadataSelected', selectedAppType);
                        }
                    });
            } else {
                this.doUpdateMapping(payload)
                .then(()=> {
                    this.doGetApplicationMetadata(selectedAppType.value);
                    this.$emit('applicationMetadataSelected', selectedAppType);
                })
            }
        },
    }
}
</script>
