<template>
    <st-page
        :title="$t('METADATA_MAPPING.PAGE_TITLE')"
    >
        <mappings-match @applicationMetadataSelected="applicationMetadataSelected"></mappings-match>
        <mappings-table ref="mappings-table"></mappings-table>
    </st-page>
</template>

<script>
    import MappingsMatch from '../components/MappingsMatch';
    import MappingsTable from '../components/MappingsTable';
    export default {
        name: 'MetadataMapping',
        components: {
            MappingsMatch,
            MappingsTable
        },
        methods: {
            applicationMetadataSelected(app) {
                this.$refs['mappings-table'].selectedAppType = app ? app.text : '';
            }
        },
    }
</script>
