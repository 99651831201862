<template>
    <div class="py-5 px-5">
        <h3 class="ml-6 my-4">{{ title }} </h3>
        <st-data-table
            :items="metadataList"
            :fields="fields"
            :modelPresenter="presenter"
            :loading="isLoading"
            :per-page="perPage"
            responsive="sm"
            hover
            :actions="actions"
            @delete="deleteMetadata"
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { MetadataMappingModel } from '@/modules/metadata-mapping/metadata-mapping-model';

    const { fields } = MetadataMappingModel;

    export default {
        name: 'MappingsTable',
        data() {
            return {
                presenter: MetadataMappingModel.presenter,
                selectedAppType: '',
                actions: [
                    {
                        name: 'delete',
                        tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                        icon: 'trash-alt',
                        customIcon: false,
                        type: 'danger'
                    },
                ],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'metadataMapping/list/allApplicationsMetadata',
                loading: 'shared/loading',
            }),
            isLoading () {
                return this.loading['metadataMapping/getApplicationMetadata'] ;
            },
            fields() {
                return [
                    fields.metadata,
                    fields.archive_metadata_name,
                    fields.archive_metadata_label,
                ]
            },
            title() {
                if (this.selectedAppType) {
                    return `${this.$t('METADATA_MAPPING.TABLE_TITLE')} pentru ${this.selectedAppType}`;
                } else {
                    return `${this.$t('METADATA_MAPPING.TABLE_TITLE')}`;
                }
            },
            metadataList() {
                return this.selectedAppType ? this.rows: [];
            },
            perPage() {
                return this.rows.length;
            }
        },
        methods: {
            ...mapActions({
                doGetApplicationMetadata: 'metadataMapping/list/getApplicationMetadata',
                deleteMappedMetadata: 'metadataMapping/list/removeMappedMetadata'
            }),
            async deleteMetadata(metadata) {
                await this.deleteMappedMetadata(metadata.item.id);
                this.doGetApplicationMetadata(metadata.item.application_type);
            }
        }
    };
</script>
