import GenericModel from '@/shared/models/generic-model';
import StringField from '@/shared/fields/string-field';
import i18n from '@/shared/plugins/vue-i18n';

const label = (name) => i18n.t(`METADATA_MAPPING.FIELDS.${name.toUpperCase()}`);

const fields = {
    metadata: new StringField(
        'metadata',
        label('metadata'),
    ),
    archive_metadata_name: new StringField(
        'archive_metadata_name',
        label('archive_metadata_name'),
    ),
    archive_metadata_label: new StringField(
        'archive_metadata_label',
        label('archive_metadata_label'),
    ),
};

export class MetadataMappingModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
